<template>
	<div class="commonHeight">
		<div class="flex header">
			<h2>过夜车辆数据分析</h2>
		</div>
		<el-form ref="form" class="searchCriteria" label-suffix=":" :model="searchForm" label-width="100px">
			<el-form-item label="停车场" class="mb-1">
				<el-select v-model="searchForm.parkId" placeholder="请选择停车场">
					<el-option label="全部" value=""></el-option>
					<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
										 :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<div class="flex">
				<el-form-item label="统计时间段">
					<el-date-picker
							v-model="searchForm.inTime"
							type="date"
							placeholder="开始时间">
					</el-date-picker>
					--
					<el-date-picker
							v-model="searchForm.outTime"
							type="date"
							placeholder="结束时间">
					</el-date-picker>
				</el-form-item>
				<div class="averageWid btnGroup">
					<el-button @click="searchData">查询</el-button>
					<el-button @click="reloadSearch">重置</el-button>
				</div>
			</div>
		</el-form>
		<!--		单天-->
		<exceedDay v-if="showOneDay" :inPark="inPark" :entryCarOneDay="entryCarOneDay" :overNightPark="overNightPark"
							 :overNightOneDay="overNightOneDay"></exceedDay>
		<!--时间段-->
		<exceedPeriod v-else :overNightOneDay="overNightOneDay2" :parkRate="parkRate" :entryCarOneDay="entryCarOneDay2"
									:overNightLine="overNightLine"></exceedPeriod>

		<div class="exceedTab">
			<div class="flex overNightBtn">
				<h3 class="tit">停车超过24小时车辆会员信息</h3>
				<el-button @click="exportData">导出</el-button>
			</div>
			<div class="table">
				<el-table
						:data="tableData"
						:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
						max-height="500px"
						style="width: 100%">
					<el-table-column
							prop="carNo" align="center" show-overflow-tooltip
							label="车牌号码"
							width="100">
					</el-table-column>
					<el-table-column align="center"
													 label="有效期起始"
													 width="100">
						<template slot-scope="scope">
							{{ scope.row.startDate|formatDay }}
						</template>
					</el-table-column>
					<el-table-column align="center" width="100"
													 label="有效期结束">
						<template slot-scope="scope">
							{{ scope.row.endDate|formatDay }}
						</template>
					</el-table-column>
					<el-table-column
							prop="memberType" align="center"
							label="用户类型"
							width="100">
					</el-table-column>
					<el-table-column
							prop="carOwner" align="center"
							label="车主姓名" show-overflow-tooltip
							width="180">
					</el-table-column>
					<el-table-column
							prop="ownerCardNo" align="center" show-overflow-tooltip
							label="身份证号" width="180">
					</el-table-column>
					<el-table-column
							prop="ownerTel" align="center"
							label="电话号码" width="120">
					</el-table-column>
					<el-table-column
							prop="ownerCompany" align="center" show-overflow-tooltip
							label="公司名称">
					</el-table-column>
					<el-table-column
							align="center"
							label="办理时间"
							width="180">
						<template slot-scope="scope">
							{{ scope.row.applyDate|formatDay }}
						</template>
					</el-table-column>
					<el-table-column
							prop="chargeParking" align="center" show-overflow-tooltip
							label="收费停车场" width="180">
					</el-table-column>
					<el-table-column
							prop="remark" align="center" show-overflow-tooltip
							label="备注">
					</el-table-column>
				</el-table>
				<el-pagination
						class="pagination"
						@current-change="handleCurrentChange"
						:current-page="page.current"
						:page-size="page.size"
						layout="prev, pager, next, total"
						:total="page.total">
				</el-pagination>
			</div>
		</div>
	</div>

</template>

<script>
import {
	getCarParkInfoList,
	getOvernightCarInfoByOneDay,
	getSentryCarStatistics,
	getOvernightCarInfoByCondition,
	getDurationAndTotalLoss,
	getParkMoreThanOneDayMemberInfo,
	getLessThanHalfHourByTime,
	exportMemberInfoOverOneDay
} from "@/api/api";
import moment from "moment";

moment.locale("zh-cn");
export default {
	name: "overnight",
	data() {
		return {
			showOneDay: true,
			searchForm: {
				parkId: "",
				inTime: "",
				outTime: ""
			},
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			parkingList: [],
			tableData: [],
			overNightPark: 0,
			overNightOneDay: {
				rows: [],
				chartData: []
			},
			overNightOneDay2: {
				rows: [],
				chartData: []
			},
			inPark: 0,
			entryCarOneDay: {
				rows: [],
				chartData: []
			},
			entryCarOneDay2: {
				rows: [],
				chartData: []
			},

			parkRate: {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE"],
				listscore: [
					{ "name": "过夜车辆数", "yValue": [] }
				]
			},
			overNightLine: {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE"],
				listscore: [
					{ "name": "停车超过24小时车辆", "yValue": [] }
				]
			},
			dateList: []
		};
	},
	mounted() {
		this.getParkingList();
		this.getData();
		this.getHalfHourCarInfo();
		this.getStatisticsNight();

	},
	methods: {
		//查询
		searchData() {
			let day = moment(this.searchForm.inTime);
			let day2 = moment(this.searchForm.outTime);
			let same = day.isSame(day2, "day");
			if (same == true || this.searchForm.inTime == "" || this.searchForm.outTime == "") {
				this.showOneDay = true;
				this.initOneDay()
			} else {
				this.showOneDay = false;
				this.initData();
			}
		},
		initOneDay(){
			this.inPark = 0
			this.entryCarOneDay = {
				rows: [],
				chartData: []
			}
			this.overNightOneDay = {
				rows: [],
				chartData: []
			},
			this.getHalfHourCarInfo();
			this.getStatisticsNight();
		},
		initData() {
			this.overNightOneDay2 = {
				rows: [],
				chartData: []
			}
			this.parkRate = {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE"],
				listscore: [{ "name": "过夜车辆数", "yValue": [] }]
			}
			this.overNightLine = {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE"],
				listscore: [
					{ "name": "停车超过24小时车辆", "yValue": [] }
				]
			},
			this.getDateByDiffTimes();
			this.getOverNightMemberByCondition();
			this.getOverNightByCondition();
			this.getParkMoreThanOneDay();
		},
		getParkingList() {
			let params = {
				page: this.page.current,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
			});
		},
		getData() {
			let searchParam = {
				page: this.page.current,
				size: this.page.size,
				oneDay: true
			};
			if (this.searchForm.inTime != "" && this.searchForm.outTime != "") {
				searchParam.entryTime = moment(this.searchForm.inTime).format("YYYY-MM-DD");
				searchParam.outTime = moment(this.searchForm.outTime).format("YYYY-MM-DD");
			}
			getParkMoreThanOneDayMemberInfo(JSON.stringify(searchParam)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
			});
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},

		//过夜车辆统计-当前时间的
		getStatisticsNight() {
			let param = {
				oneDay: true,
				inTime: moment().subtract(2,'days').format("YYYY-MM-DD"),
				parkId: this.searchForm.parkId
			};
			getOvernightCarInfoByOneDay(JSON.stringify(param)).then(res => {
				let arr = [], arr2 = [];
				this.overNightPark = 0
				res.data.data.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
					this.overNightPark = this.overNightPark + i.memberTypeNum;
				});
				if (arr.length > 0 && arr2.length > 0) {
					this.overNightOneDay.rows = arr;
					this.overNightOneDay.chartData = arr2;
				}
			});
		},

		//停车超过24小时-当前时间的
		getHalfHourCarInfo() {
			let param = {
				outTime: moment().subtract(1,'days').format("YYYY-MM-DD"),
				oneDay: true,
				parkId: this.searchForm.parkId
			};
			getSentryCarStatistics(JSON.stringify(param)).then(res => {
				this.inPark = res.data.data.count;
				let arr = [], arr2 = [];
				res.data.data.list.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
				});
				if (arr.length > 0 && arr2.length > 0) {
					this.entryCarOneDay.rows = arr;
					this.entryCarOneDay.chartData = arr2;
				}
			});
		},

		//过夜车辆统计-时间段（会员类型统计）
		getOverNightMemberByCondition() {
			if (this.searchForm.inTime != "" && this.searchForm.outTime != "") {
				this.searchForm.inTime = moment(this.searchForm.inTime).format("YYYY-MM-DD");
				this.searchForm.outTime = moment(this.searchForm.outTime).format("YYYY-MM-DD");
			}
			getOvernightCarInfoByOneDay(JSON.stringify(this.searchForm)).then(res => {
				let arr = [], arr2 = [];
				res.data.data.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
				});
				this.overNightOneDay2.rows = arr;
				this.overNightOneDay2.chartData = arr2;
			});

		},

		//过夜车辆统计-时间段
		getOverNightByCondition() {
			if (this.searchForm.inTime != "" && this.searchForm.outTime != "") {
				this.searchForm.inTime = moment(this.searchForm.inTime).format("YYYY-MM-DD");
				this.searchForm.outTime = moment(this.searchForm.outTime).format("YYYY-MM-DD");
			}
			getOvernightCarInfoByCondition(JSON.stringify(this.searchForm)).then(res => {
				this.parkRate.xValue = this.dateList;
				let map = new Map();
				let data = res.data.data;
				data.forEach(i => {
					map.set(i.inTimeDay, i.memberTypeNum);
				});
				this.dateList.forEach(o => {
					if (map.has(o)) {
						this.parkRate.listscore[0].yValue.push(map.get(o));
					} else {
						this.parkRate.listscore[0].yValue.push(0);
					}
				});
				//this.parkRate.xValue = data.map(i => i.inTimeDay);
				//this.parkRate.listscore[0].yValue = data.map(i => i.memberTypeNum);

			});

		},

		//停车超过24小时-时间段
		getParkMoreThanOneDay() {
			let param = {
				oneDay: true,
				parkId: this.searchForm.parkId
			};
			if (this.searchForm.inTime != "" && this.searchForm.outTime != "") {
				param.entryTime = moment(this.searchForm.inTime).format("YYYY-MM-DD");
				param.outTime = moment(this.searchForm.outTime).format("YYYY-MM-DD");
			}
			getLessThanHalfHourByTime(JSON.stringify(param)).then(res => {
				let arr = [], arr2 = [];
				res.data.data.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
				});
				this.entryCarOneDay2.rows = arr;
				this.entryCarOneDay2.chartData = arr2;

			});

			getDurationAndTotalLoss(JSON.stringify(param)).then(res => {
				this.overNightLine.xValue = this.dateList;
				let data = res.data.data.list;
				let map = new Map();
				data.forEach(i => {
					map.set(i.outTimeDay, i.outNum);
				});
				this.dateList.forEach(o => {
					if (map.has(o)) {
						this.overNightLine.listscore[0].yValue.push(map.get(o));
					} else {
						this.overNightLine.listscore[0].yValue.push(0);
					}
				});
				//this.overNightLine.xValue = data.map(i => i.outTimeDay);
				//this.overNightLine.listscore[0].yValue = data.map(i => i.outNum);
			});
		},

		reloadSearch() {
			this.searchForm = {
				parkId: "",
				inTime: "",
				outTime: ""
			};
			this.showOneDay = true;
			this.getData();
			this.getHalfHourCarInfo();
			this.getStatisticsNight();
		},

		getDateByDiffTimes() {
			let begin = moment(this.searchForm.inTime).format("YYYY-MM-DD");
			let end = moment(this.searchForm.outTime).format("YYYY-MM-DD");
			let diff = moment(end).diff(begin, "days");
			for (let i = 0; i <= diff; i++) {
				this.dateList.push(begin);
				begin = moment(begin).add(1, "days").format("YYYY-MM-DD");
			}
		},

		exportData() {
			let searchParam = {
				oneDay: true
			};
			if (this.searchForm.inTime != "" && this.searchForm.outTime != "") {
				searchParam.entryTime = moment(this.searchForm.inTime).format("YYYY-MM-DD");
				searchParam.outTime = moment(this.searchForm.outTime).format("YYYY-MM-DD");
			}
			exportMemberInfoOverOneDay(JSON.stringify(searchParam));
		}
	},
	components: {
		"exceedDay": () => import("./exceedDay"),
		"exceedPeriod": () => import("./exceedPeriod")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/overNight";
</style>
